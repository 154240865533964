export const MENU_ICON = {
  'dev': 'home-filled',
  'gp': 'app-tray-filled',
  'home': 'hb-menu',
  'dev-metrics': 'edit-filled',
  'risk': 'warning-triangle-filled',
  'automation': 'settings',
  'qa-management': 'bar-graph',
  'dev-charts': 'pie-chart',
  'scrum-team': 'user-group-filled',
  'cadence-chart': 'fa-solid fa-chart-line'
};

export const SPRINT_STATUS_CODE = {
  GREEN: 1,
  YELLOW: 2,
  RED: 3
};

export const SPRINT_STATUS = [
  {
    id: '1',
    displayName: 'Green'
  },
  {
    id: '2',
    displayName: 'Yellow'
  },
  {
    id: '3',
    displayName: 'Red'
  }
];

export const SPRINT_PROGRESS_STATUS = [
  {
    id: 'Not_Started',
    displayName: 'Not Started'
  },
  {
    id: 'In_Progress',
    displayName: 'In Progress'
  },
  {
    id: 'In_Progress_BA',
    displayName: 'In Progress BA'
  },
  {
    id: 'In_Progress_Dev',
    displayName: 'In Progress Dev'
  },
  {
    id: 'In_Progress_QA',
    displayName: 'In Progress QA'
  },
  {
    id: 'In_Progress_UAT',
    displayName: 'In Progress UAT'
  },
  {
    id: 'Approved_For_Production',
    displayName: 'Approved For Production'
  },
  {
    id: 'Completed',
    displayName: 'Completed'
  },
  {
    id: 'On_Hold',
    displayName: 'On Hold'
  }
];

export const SPRINT_PROGRESS_STATUS_CODE = [
  'Not Started',
  'In Progress',
  'In Progress-BA',
  'In Progress-Dev',
  'In Progress-QA',
  'In Progress-UAT',
  'Approved For Production',
  'Completed',
  'On Hold'
];

export const SPRINT_SCOPE = [
  {
    id: 'Not_Locked',
    displayName: 'Not Locked'
  },
  {
    id: 'Locked',
    displayName: 'Locked'
  }
];

export const MAX_CADENCE = 4;

export const VAR_LOCATIONS = {
  XIAN: `cn-xi'an`,
  CHENGDU: 'cn-chengdu'
};

export const MODULE_ROLE_PROJECT_ID = '10000000-0000-0000-0000-000000000001';

export const MONTHS_FULL = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

export const MONTHS_SHORT = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const BILLING_START_YEAR = 2020;

export const QUERY_MODE = ['Monthly', 'Quartly', 'Yearly'];

export const QUERY_MODE_CODE = {
  Monthly: 0,
  Quartly: 1,
  Yearly: 2
};

export const QUARTERS = ['Q1 (Jan-Mar)', 'Q2 (Apr-Jun)', 'Q3 (Jul-Sep)', 'Q4 (Oct-Dec)'];

export const QUARTERS_CODE = {
  'Q1 (Jan-Mar)': 1,
  'Q2 (Apr-Jun)': 2,
  'Q3 (Jul-Sep)': 3,
  'Q4 (Oct-Dec)': 4
};

export const EMPLOYEETYPES = [
  '', 'FTE', 'CWE', 'External'
];

export const PROJECT_PERMISSIONS = {
  RISK: 'Project.Risk.Update',
  RELEASE: 'Project.Release.Update',
  PROJECTHOME: 'Project.ProjectSummary.Update',
  DEVMETRIC: 'Project.DevMetric.Update',
  SCRUMTEAM: 'Project.ScrumTeam.Update',
  DEVCHART: 'Project.DevChart.UpdateProjectChartSetting',
  RETROBOARD: 'Project.RetroBoard.Admin'
};

export const EMPLOYEETYPES_CODE = {
  FTE: 1,
  CWE: 2,
  External: 3
};

export const RETRO_STATUS = {
  OPEN: 0,
  CLOSED: 1
};

export const RETRO_ACTIONPLAN_STATUS = {
  ALL: -1,
  UNRESOLVED: 0,
  RESOLVED: 1
};

export const RETRO_RECORD_TYPE_DICT = {
  1: 'well',
  2: 'wrong',
  3: 'suggestion',
  4: 'actionPlan'
};

export const PERMISSION_ROLETYPE = {
  MODULE: 0,
  PROJECT: 1
};

export const SP_CHANGE_TYPES = {
  TicketSwap: 0,
  SPChange: 1
};
