import {
  GET_MENU,
  GET_HALLOFFAMES,
  CREATE_HALLOFFAMER,
  DELETE_HALLOFFAMER,
  LIKE_HALLOFFAMER,
  APPROVE_HALLOFFAMER,
  GET_AVALIABLE_JIRA_SPRINT,
  CREATE_SPRINT,
  GET_PROJECT,
  GET_PROJECT_SUMMARY,
  GET_PROJECT_STATUS,
  GET_ORGANIZATION_ORGANIZATIONS,
  PROJECT_BASICINFO,
  UPDATE_PROJECT_BASICINFO,
  PROJECT_ALL,
  UPDATE_SCRUM_TEAMS,
  PROJECT_MANAGERS,
  GET_DEVMETRICS,
  CREATE_DELETE_DEVMETRIC,
  UPDATE_DEVMETRIC,
  GET_QA_MANAGEMENT_SUMMARY,
  GET_CADENCE_CHART_SUMMARY,
  GET_QA_CHART_SETTING,
  GET_RULE_VALUE_TARGET,
  BASE_QA_CHART_SETTING,
  GET_DEVCHARTS,
  GET_SCRUM_TEAMS,
  GET_USER_INFOS,
  UPDATE_USER_LOGOUT,
  USER_INFO,
  USER_ALLOCATION,
  GET_USER_ROLES,
  UPDATE_USER_ROLE_ADD,
  UPDATE_USER_ROLE_DELETE,
  GET_PROJECT_ROLES,
  GET_RULE_HISTORY,
  UPDATE_RULE_HISTORY,
  UPDATE_PROJECT_HEALTH,
  UPDATE_USERINFO_AVATAR,
  GET_ALL_DICTTYPE,
  GET_USER_PROJECTS_CURRENT_SPRINT,
  MENU_QUICK_ACTION,
  DELETE_MENU_QUICK_ACTIONS,
  GET_USER_PERMISSIONS,
  GET_PROJECT_HEALTH_BY_PROJECT_STATUS,
  GET_USER_TYPE_ALLOCATION,
  GET_ALL_SCRUM_TEAMS,
  GET_ALL_CURRENT_RELEASE,
  GET_ALL_PROJECT_RISKDAYS,
  GET_ALL_PROJECT_MILESTONE,
  GET_ALL_MILESTONE_RISKDAYS,
  GET_ALL_SPRINT_RISKDAYS,
  SYNC_USER_FROM_KEYCLOAK
} from './Api';
import request from './axiosConfig';
import { objectToQueryParams } from './utils';

/**  welcome page  */

const getUserProjectsCurrentSprint = (userId, notShowSprint=false) => request.get(GET_USER_PROJECTS_CURRENT_SPRINT + `?userId=${userId}&notShowSprint=${notShowSprint}`);
const createMenuQuickAction = (params) => request.post(MENU_QUICK_ACTION, params);
const deleteMenuQuickActions = (params) => request.put(DELETE_MENU_QUICK_ACTIONS, params);
const getMenuQuickActions = (userId) => request.get(MENU_QUICK_ACTION + `?UserId=${userId}`);

const getMenus = () => request.get(GET_MENU);
const getHallOfFames = () => request.get(GET_HALLOFFAMES);
const createHallOfFamer = (params) => request.post(CREATE_HALLOFFAMER, params);
const deleteHallOfFamer = (id) => request.delete(`${DELETE_HALLOFFAMER}${id}`);
const likeHallOfFamer = (params) => request.post(LIKE_HALLOFFAMER, params);
const approvalHallOfFamer = (id) => request.get(`${APPROVE_HALLOFFAMER}${id}`);

const getRequest = (projectId, url) =>
  request.get(`${url}?__project=${projectId}`, {
    headers: { __project: projectId }
  });

const updateRequest = (projectId, params, url) =>
  request.put(`${url}?__project=${projectId}`, params, {
    headers: { __project: projectId }
  });

/* sprint grid Requests */
const getAvailableSprint = (params) =>
  request.get(`${GET_AVALIABLE_JIRA_SPRINT}${objectToQueryParams(params)}`, {
    headers: { __project: params.__projectId }
  });

const createSprint = (projectId, params) => request.post(`${CREATE_SPRINT}?__project=${projectId}`, params);
const updateSprint = (projectId, params, sprintId = '') =>
  request.put(`${CREATE_SPRINT}${sprintId}?__project=${projectId}`, params);
const deleteSprint = (projectId, sprintId) => request.delete(`${CREATE_SPRINT}${sprintId}?__project=${projectId}`);

/* QA Chart Requests */
const getQaManagementSummary = (params) =>
  request.get(`${GET_QA_MANAGEMENT_SUMMARY}${objectToQueryParams(params)}`, {
    headers: { __project: params.ProjectId }
  });
/* Cadence Chart Requests */
const getCadenceChartSummary = (params) =>
  request.get(`${GET_CADENCE_CHART_SUMMARY}${objectToQueryParams(params)}`, {
    headers: { __project: params.ProjectId }
  });

const getQaChartSetting = (projectId) => request.get(`${GET_QA_CHART_SETTING}?__project=${projectId}`);
const updateQaChartSetting = (params) => request.put(BASE_QA_CHART_SETTING, params);

const getRuleValue = (projectId, rule) => request.get(`${GET_RULE_VALUE_TARGET}?ProjectId=${projectId}&rule=${rule}`);

/* Project Home Requests */
const getProject = (projectId) => request.get(GET_PROJECT + projectId, {
  headers: { __project: projectId }
});
const getProjectSummary = (obj) => request.get(GET_PROJECT_SUMMARY + objectToQueryParams(obj));
const getProjectHealth = (projectId) => request.get(GET_PROJECT_STATUS + `?__project=${projectId}`);
const getOrganizations = (projectId) => request.get(`${GET_ORGANIZATION_ORGANIZATIONS}${projectId}`);
const updateProjectSummary = (projectId, summaryId, body) =>
  request.put(GET_PROJECT_SUMMARY + summaryId + `?__project=${projectId}`, body);
const addProjectSummary = (projectId, body) => request.post(GET_PROJECT_SUMMARY + `?__project=${projectId}`, body);
const deleteProjectSummary = (projectId, summaryId) =>
  request.delete(GET_PROJECT_SUMMARY + summaryId + `?__project=${projectId}`);

/* Project Home Requests */
const getProjectBasicInfo = (projectId) => getRequest(projectId, PROJECT_BASICINFO);
const getProjectAll = (projectId) => getRequest(projectId, PROJECT_ALL);
const updateProjectBasicInfo = (projectId, params) => updateRequest(projectId, params, UPDATE_PROJECT_BASICINFO);
const getScrumTeams = (projectId) => getRequest(projectId, GET_SCRUM_TEAMS);
const updateCadence = (projectId, scrumTeamId, params) =>
  request.put(`${UPDATE_SCRUM_TEAMS}${scrumTeamId}`, params, {
    headers: { __project: projectId }
  });
const updateProjectManagers = (projectId, params) => updateRequest(projectId, params, PROJECT_MANAGERS);
const deleteProjectManagers = (projectId) => {
  return request.delete(`${PROJECT_MANAGERS}?__project=${projectId}`, {
    headers: { __project: projectId }
  });
};
const getRuleHistory = (projectId) => getRequest(projectId, GET_RULE_HISTORY);
const updateRuleHistory = (projectId, params) => updateRequest(projectId, params, UPDATE_RULE_HISTORY);
const updateProjectHealth = (projectId, projectHealthId, params) =>
  request.put(`${UPDATE_PROJECT_HEALTH}${projectHealthId}`, params, {
    headers: { __project: projectId }
  });

/* dev metrics section */
const getDevMetrics = (projectId) => getRequest(projectId, GET_DEVMETRICS);
const createDevMetrics = (projectId, params) =>
  request.post(`${CREATE_DELETE_DEVMETRIC}`, params, {
    headers: { __project: projectId }
  });
const updateDevMetrics = (projectId, params) => updateRequest(projectId, params, UPDATE_DEVMETRIC);
const deleteDevMetric = (projectId, id) =>
  request.delete(`${CREATE_DELETE_DEVMETRIC}${id}`, {
    headers: { __project: projectId }
  });

/* usermanagement section */
const getUserInfos = (params) =>
  request.get(params ? `${GET_USER_INFOS}${objectToQueryParams(params)}` : GET_USER_INFOS);

const getUserRoles = (params) =>
  request.get(params ? `${GET_USER_ROLES}${objectToQueryParams(params)}` : GET_USER_ROLES);

const getUserPermissions = (params) =>
  request.get(params ? `${GET_USER_PERMISSIONS}${objectToQueryParams(params)}` : GET_USER_PERMISSIONS);

const updateUserRoleAdd = (params) => request.post(`${UPDATE_USER_ROLE_ADD}`, params);

const updateUserRoleDelete = (params) => request.post(`${UPDATE_USER_ROLE_DELETE}`, params);

const getProjectRoles = (params) =>
  request.get(params ? `${GET_PROJECT_ROLES}${objectToQueryParams(params)}` : GET_PROJECT_ROLES);

const createUserInfo = (params) => request.post(USER_INFO, params);
const updateUserInfo = (params) => request.put(USER_INFO, params);
const createUserAllocation = (params) => request.post(USER_ALLOCATION, params);
const updateUserAllocation = (params) => request.put(USER_ALLOCATION, params);
const deleteUserAllocation = (id) => request.delete(USER_ALLOCATION + id);
const updateUserLogout = () => request.put(UPDATE_USER_LOGOUT);
const syncUserFromKeycloak = () => request.post(SYNC_USER_FROM_KEYCLOAK);

/* dev charts section */
const getDevCharts = (params) =>
  request.get(`${GET_DEVCHARTS}${params.CategoryName}?${params.ProjectId}`, {
    headers: { __project: params.ProjectId }
  });

/* Header */
const getUserInfoAvatar = (uid) => request.get(`${USER_INFO}${uid}`);

const createUserInfoAvatar = (userInfoAvatar) => request.post(USER_INFO, userInfoAvatar);

const updateUserInfoAvatar = (userInfoAvatar) => request.put(UPDATE_USERINFO_AVATAR, userInfoAvatar);

const getAllDictType = () => request.get(GET_ALL_DICTTYPE);

/* Executive summary */
const getProjectHealthByProjectStatus = (params) => request.get(`${GET_PROJECT_HEALTH_BY_PROJECT_STATUS}${objectToQueryParams(params)}`);
const getAllUserTypeAllocation = () => request.get(GET_USER_TYPE_ALLOCATION);
const getAllScrumTeams = () => request.get(GET_ALL_SCRUM_TEAMS);
const getAllCurrentRelease = () => request.get(GET_ALL_CURRENT_RELEASE);
const getAllProjectRiskDays = () => request.get(GET_ALL_PROJECT_RISKDAYS);
const getAllProjectMilestone = () => request.get(GET_ALL_PROJECT_MILESTONE);
const getAllMilestoneRiskDays = () => request.get(GET_ALL_MILESTONE_RISKDAYS);
const getAllSprintRiskDays = () => request.get(GET_ALL_SPRINT_RISKDAYS);

export {
  createMenuQuickAction,
  deleteMenuQuickActions,
  getMenuQuickActions,
  getUserProjectsCurrentSprint,
  getMenus,
  getHallOfFames,
  createHallOfFamer,
  deleteHallOfFamer,
  getAvailableSprint,
  createSprint,
  updateSprint,
  deleteSprint,
  getUserInfos,
  updateUserLogout,
  getUserRoles,
  getUserPermissions,
  updateUserRoleAdd,
  updateUserRoleDelete,
  getProjectRoles,
  createUserInfo,
  updateUserInfo,
  createUserAllocation,
  updateUserAllocation,
  deleteUserAllocation,
  likeHallOfFamer,
  approvalHallOfFamer,
  getProject,
  getProjectSummary,
  getProjectHealth,
  getOrganizations,
  getProjectBasicInfo,
  getProjectAll,
  updateProjectBasicInfo,
  updateProjectManagers,
  deleteProjectManagers,
  updateCadence,
  getDevMetrics,
  createDevMetrics,
  updateDevMetrics,
  deleteDevMetric,
  getQaManagementSummary,
  getCadenceChartSummary,
  getQaChartSetting,
  updateQaChartSetting,
  getRuleValue,
  getScrumTeams,
  addProjectSummary,
  deleteProjectSummary,
  updateProjectSummary,
  getUserInfoAvatar,
  createUserInfoAvatar,
  updateUserInfoAvatar,
  getDevCharts,
  getRuleHistory,
  updateRuleHistory,
  updateProjectHealth,
  getAllDictType,
  getProjectHealthByProjectStatus,
  getAllUserTypeAllocation,
  getAllScrumTeams,
  getAllCurrentRelease,
  getAllProjectRiskDays,
  getAllProjectMilestone,
  getAllMilestoneRiskDays,
  getAllSprintRiskDays,
  syncUserFromKeycloak
};
